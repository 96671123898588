export const locales = ["en", "fi"];
export type Locale = (typeof locales)[number];
export const defaultLocale: Locale = "fi";
export const localeNames: Record<Locale, string> = {
  en: "English",
  fi: "Suomi",
};
export const authLocaleNames: Record<Locale, string> = {
  en: "EN",
  fi: "FI",
};

export const countries = ["FIN", "SWE"] as const;
export type Countries = (typeof countries)[number];
export const defaultCountry: Countries = "FIN";
export const CountryNames: Record<Countries, string> = {
  FIN: "Finland",
  SWE: "Sweden",
};

export enum Roles {
  OWNER = "org:owner",
  ADMIN = "org:admin",
  MANAGER = "org:manager",
  MEMBER = "org:member",
}

export type Role = keyof typeof Roles;
