import { AuthError } from "aws-amplify/auth";
import {
  ChangePasswordException,
  ConfirmForgotPasswordException,
  ConfirmSignUpException,
  ForgotPasswordException,
  InitiateAuthException,
  SignUpException,
} from "./errors";

export const getAuthError = (error: AuthError) => {
  switch (error.name) {
    case InitiateAuthException.UserNotFoundException:
      return "invalid_credentials";
    case InitiateAuthException.NotAuthorizedException:
      return "invalid_credentials";
    case InitiateAuthException.UserNotConfirmedException:
      return "user_not_confirmed";
    case InitiateAuthException.TooManyRequestsException:
      return "too_many_requests";
  }
};

export const getSignUpError = (error: AuthError) => {
  switch (error.name) {
    case SignUpException.UsernameExistsException:
      return "user_exists";
    case SignUpException.InvalidPasswordException:
      return "invalid_password";
  }
};

export const getSignUpConfirmError = (error: AuthError) => {
  switch (error.name) {
    case ConfirmSignUpException.CodeMismatchException:
      return "code_mismatch";
    case ConfirmSignUpException.ExpiredCodeException:
      return "code_expired";
    case ConfirmSignUpException.UserNotFoundException:
      return "user_not_found";
    case ConfirmSignUpException.TooManyFailedAttemptsException:
      return "too_many_failed_attempts";
    case ConfirmSignUpException.LimitExceededException:
      return "too_many_failed_attempts";
  }
};

export const getChangePasswordError = (error: AuthError) => {
  switch (error.name) {
    case ChangePasswordException.InvalidPasswordException:
      return "invalid_password";
  }
};

export const getResetPasswordError = (error: AuthError) => {
  switch (error.name) {
    case ForgotPasswordException.TooManyRequestsException:
      return "too_many_requests";
    case ForgotPasswordException.LimitExceededException:
      return "too_many_requests";
    case ForgotPasswordException.UserNotFoundException:
      return "user_not_found";
  }
};

export const getResetPasswordConfirmError = (error: AuthError) => {
  switch (error.name) {
    case ConfirmForgotPasswordException.CodeMismatchException:
      return "code_mismatch";
    case ConfirmForgotPasswordException.ExpiredCodeException:
      return "code_expired";
    case ConfirmForgotPasswordException.InvalidPasswordException:
      return "invalid_password";
    case ConfirmForgotPasswordException.LimitExceededException:
      return "too_many_requests";
    case ConfirmForgotPasswordException.TooManyFailedAttemptsException:
      return "too_many_failed_attempts";
    case ConfirmForgotPasswordException.TooManyRequestsException:
      return "too_many_requests";
    case ConfirmForgotPasswordException.UserNotFoundException:
      return "user_not_found";
    case ConfirmForgotPasswordException.UserNotConfirmedException:
      return "user_not_confirmed";
  }
};
