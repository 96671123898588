"use client";
import { useTranslations } from "next-intl";
import Link from "next/link";
import AuthLanguageSelect from "@/components/ui/user-settings/auth-language-select";

export const OnboardingFooter = () => {
  const t = useTranslations("Onboarding");

  return (
    <footer className="flex flex-col justify-center items-center gap-5 max-sm:gap-2.5 max-sm:text-sm">
      <AuthLanguageSelect />
      <div className="inline-flex gap-5 text-center">
        <Link href="http://evenli.com/privacy-statement-eu/" target="_blank">
          <p>
            {t("privacy_policy")} & {t("terms_of_service")}
          </p>
        </Link>
      </div>
      <p className="text-center">
        <Link href="mailto:info@evenli.com" passHref>
          info@evenli.com
        </Link>
      </p>
    </footer>
  );
};
