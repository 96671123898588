import { Components, Paths } from "@/openapi";
import { z } from "zod";
import { countries } from "./constants";

export type Memberships =
  Paths.GetUserMemberships.Responses.$200["memberships"];

// Schema for SIGN IN form
export const signInSchema = z.object({
  email: z.string().email({ message: "enter_a_valid_email_address" }),
  password: z.string().min(8, "password_must_be_at_least_8_characters"),
});
export type SignInSchemaType = z.infer<typeof signInSchema>;

export const codeRequestFormSchema = z.object({
  email: z.string().email({ message: "enter_a_valid_email_address" }),
});
export type CodeRequestSchemaType = z.infer<typeof codeRequestFormSchema>;

export const newPasswordSchema = z
  .object({
    code: z.string().min(1, "this_field_is_required"),
    password: z
      .string()
      .min(8, "password_must_be_at_least_8_characters")
      .refine((value) => /[a-z]/.test(value), {
        message: "password_must_contain_lowercase",
      })
      .refine((value) => /[A-Z]/.test(value), {
        message: "password_must_contain_uppercase",
      })
      .refine((value) => /[0-9]/.test(value), {
        message: "password_must_contain_number",
      })
      .refine((value) => /[\W_]/.test(value), {
        message: "password_must_contain_special_character",
      }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwords_must_match",
    path: ["confirmPassword"],
  });
export type NewPasswordSchemaType = z.infer<typeof newPasswordSchema>;

// Schema for SIGN UP form
export const signUpSchema = z
  .object({
    email: z.string().email(),
    firstName: z.string().min(1, "this_field_is_required"),
    lastName: z.string().min(1, "this_field_is_required"),
    phoneNumber: z
      .string()
      .regex(/^\+\d*$/, "phone_number_must_be_valid")
      .min(10, "phone_number_is_too_short"),
    password: z
      .string()
      .min(8, "password_must_be_at_least_8_characters")
      .refine((value) => /[a-z]/.test(value), {
        message: "password_must_contain_lowercase",
      })
      .refine((value) => /[A-Z]/.test(value), {
        message: "password_must_contain_uppercase",
      })
      .refine((value) => /[0-9]/.test(value), {
        message: "password_must_contain_number",
      })
      .refine((value) => /[\W_]/.test(value), {
        message: "password_must_contain_special_character",
      }),
    confirmPassword: z.string(),
    terms: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwords_must_match",
    path: ["confirmPassword"],
  });

export type SignUpSchemaType = z.infer<typeof signUpSchema>;

// Schema for ORGANISATION DETAILS form
export const orgDetailsSchema = z.object({
  companyName: z.string().min(1, "company_name_is_required"),
  country: z.enum(countries, { message: "country_is_required" }),
  city: z.string().min(1, "city_is_required"),
  address: z.string().min(1, "this_field_is_required"),
  postalCode: z.string().min(5, "min_5_digits"),
});
export type OrgDetailsSchemaType = z.infer<typeof orgDetailsSchema>;

// Schema for BILLING INFORMATION form
export const billingInfoSchema = z
  .object({
    businessId: z.string().min(1, "this_field_is_required"),
    billingEmail: z.string().email(),
    billingBusinessName: z.string().optional(),
    billingEinvoicingAddress: z.string().min(1, "this_field_is_required"),
    billingEinvoicingOperator: z.string().min(1, "this_field_is_required"),
    billingAdditionalInformation: z.string().optional(),
    billingAddressLine: z.string().optional(),
    billingCity: z.string().optional(),
    billingZipcode: z.string().optional(),
    billingCountry: z.enum(countries).optional(),
    checkBox: z.boolean(),
  })
  .refine(
    (data) => {
      return !data.checkBox || !!data.billingAddressLine;
    },
    {
      message: "this_field_is_required",
      path: ["billingAddressLine"],
    },
  )
  .refine(
    (data) => {
      return !data.checkBox || !!data.billingCity;
    },
    {
      message: "this_field_is_required",
      path: ["billingCity"],
    },
  )
  .refine(
    (data) => {
      return !data.checkBox || !!data.billingCountry;
    },
    {
      message: "this_field_is_required",
      path: ["billingCountry"],
    },
  )
  .refine(
    (data) => {
      return !data.checkBox || !!data.billingZipcode;
    },
    {
      message: "this_field_is_required",
      path: ["billingZipcode"],
    },
  );
// .refine(
//   (data) => {
//     return !data.checkBox || !!data.billingCountry;
//   },
//   {
//     message: "this_field_is_required",
//     path: ["billingCountry"],
//   },
// );
export type BillingInfoSchemaType = z.infer<typeof billingInfoSchema>;

// Schema for GENERAL ORGANISATION SETTINGS form
export const orgSettingsSchema = z.object({
  companyName: z.string().min(1, "company_name_is_required"),
  country: z.string().min(1, "country_is_required"),
  city: z.string().min(1, "city_is_required"),
  address: z.string().min(1, "this_field_is_required"),
  postalCode: z.string().min(5, "min_5_digits"),
});
export type TOrgSettingsSchema = z.infer<typeof orgSettingsSchema>;

// Schema for ELECTRICITY PRICING SETTINGS form
export const electPricingSchema = z.object({
  universalPrice: z.string(),
});
export type TElectPricingSchema = z.infer<typeof electPricingSchema>;

// Schema for ELECTRICITY PRICING SETTINGS form
export const accountSettingsSchema = z.object({
  firstName: z.string().min(1, "first_name_is_required"),
  lastName: z.string().min(1, "last_name_is_required"),
});
export type TAccountSettingsSchema = z.infer<typeof accountSettingsSchema>;

// Schema for ELECTRICITY PRICING SETTINGS form
export const inviteUserSchema = z.object({
  roles: z.array(z.string()).nonempty("at_least_one_role_must_be_selected"),
  sites: z.array(z.string()).nonempty("at_least_one_site_must_be_selected"),
  email: z.string().email("invalid_email"),
});
export type TInviteUserSchema = z.infer<typeof inviteUserSchema>;

// Schema for SITE CHANGING form
export const siteChangeSchema = z.object({
  name: z.string(),
  publicName: z.string().optional(),
  streetAddress: z.string(),
  city: z.string(),
  zipCode: z.string(),
  country: z.enum(countries),
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
  visibleOnMap: z.boolean(),
  isPublic: z.boolean(),
  checkBox: z.boolean(),
  price: z.string().nullable(),
});

export enum Status {
  NotInitialized = "",
  InvitationNotFound = "Invitation not found",
  SomethingWentWrong = "Something went wrong",
  Authorized = "Authorized user",
  Unauthorized = "Unauthorized user",
}

export type OrganizationSites =
  Paths.GetOrganizationSites.Responses.$200["sites"];

export type OrganizationStatistics = Paths.GetStatistics.Responses.$200;
export type OrganizationStatisticsOverview =
  Paths.GetStatisticsOverview.Responses.$200;

export type OrganizationSite = Components.Schemas.OneChargerGroupReply;

export type Charger = Components.Schemas.ChargerReply;
